import { Component, Inject, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { AccountService } from 'src/app/admin-portal/modules/account/services/account.service';
import { ProjectService } from 'src/app/admin-portal/modules/project/services/project.service';
import { ResourceService } from 'src/app/admin-portal/modules/resource/services/resource.service';
import { whitespaceValidator } from 'src/app/utils/whitespace-validation.utils';

@Component({
  selector: 'app-dialog-resource',
  templateUrl: './resource-dialog.html',
})
export class ResourceDialogComponent {
  resourceForm!: FormGroup;
  isEditMode: boolean = false;
  costCentreList: any[] = [];
  projectId: string = '';
  resource: any[] = [];
  resourceList: any[] = [];
  projectList: any[] = [];
  isButtonDisabled: boolean = true;
  errorMessage: string = '';
  isSubmitting: boolean = false;
  filteredResources: any[] = [];
  noResourcesFound: boolean = false;
  selectedResourceId: string = '';
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'createdAt' = 'createdAt';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  phoneNumberValid: boolean = true;
  currencies: any[] = [];
  filteredCurrencies: any[] = [];
  currencyControl = new FormControl();
  userID!: string;

  private unsubscribe: Subject<void> = new Subject();
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    public matDialog: MatDialogRef<ResourceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private resourceService: ResourceService,
    private globalService: GlobalService,
    private projectService: ProjectService,
    private accountService: AccountService
  ) {}

  @ViewChild('autoResource') autoResource!: MatAutocomplete;

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user') || '');
    this.userID = user.userID;
    this.isEditMode = this.data.isEditMode;
    this.initializeForm();
    this.loadCurrencies();
    this.subscribeToCurrencyControl();

    if (this.isEditMode) {
      this.resourceService
        .getResourceById(this.data.element.resourceID)
        .subscribe((res: any) => {
          this.resourceForm.patchValue({
            name: res.data.name,
            email: res.data.email,
            currency: res.data.currency,
            countryCode: res.data.countryCode,
            phone: res.data.phone,
            salary: res.data.salary,
            role: res.data.role,
            startDate: res.data.startDate,
            resourceID: res.data.resourceID,
          });
        });
    }

    this.resourceForm
      .get('name')
      ?.valueChanges.pipe(debounceTime(300), takeUntil(this.onDestroy$))
      .subscribe((searchText: string) => {
        if (searchText && searchText.length >= 1) {
          this.loadResourceName(
            this.pageNumber,
            this.limit,
            searchText,
            'createdAt',
            'ASC'
          );
        } else {
          this.filteredResources = [];
        }
      });

    this.resourceForm.valueChanges.subscribe(() => {
      this.isButtonDisabled =
        this.resourceForm.invalid || !this.resourceForm.dirty;
    });

    this.isButtonDisabled = true;
  }

  subscribeToCurrencyControl(): void {
    this.currencyControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.globalService.componentDestroyed(this))
      )
      .subscribe((searchTerm: string) => {
        this.filteredCurrencies = this.filterCurrencies(searchTerm);
      });
  }

  loadCurrencies(): void {
    this.accountService
      .getCurrency()
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.currencies = data.data.records;
          this.filteredCurrencies = this.currencies;
        },
        error: (error: any) => {
          console.error('Error in getCurrencies request:', error);
        },
      });
  }

  filterCurrencies(searchTerm: string): any[] {
    if (!searchTerm) {
      return this.currencies;
    }
    return this.currencies.filter((currency) =>
      currency.currency.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  initializeForm(): void {
    this.resourceForm = this.formBuilder.group({
      name: ['', [Validators.required, whitespaceValidator()]],
      email: ['', [Validators.email, Validators.required]],
      countryCode: ['', Validators.required],
      phone: ['', Validators.required],
      currency: ['', Validators.required],
      salary: ['', Validators.required],
      role: ['', [Validators.required, whitespaceValidator()]],
      startDate: [''],
      resourceID: [''],
    });

    if (!this.isEditMode) {
      this.resourceForm.addControl('projectId', this.formBuilder.control(''));
    }

    if (this.isEditMode) {
      this.resourceForm.addControl('resourceID', this.formBuilder.control(''));
    }
  }

  loadResourceList(): void {
    const MAX_LIMIT = 9999;
    this.resourceService
      .getResourceList(0, MAX_LIMIT, '', 'createdAt', 'ASC')
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((response: any) => {
        this.resourceList = response.data.records;
      });
  }

  loadResourceName(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: 'createdAt',
    orderBy: 'ASC' | 'DESC'
  ): void {
    this.resourceService
      .getResourceList(pageNumber, limit, search, sortBy, orderBy)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.resourceList = data?.data?.records;
          this.filteredResources = this.resourceList.slice();
        },
        error: (error: any) => {
          console.error('Error in fetching resource list:', error);
        },
      });
  }

  onResourceInput(event: any): void {
    const searchText: string = (event.srcElement.value || '').toLowerCase();
    if (this.resourceList) {
      this.filteredResources = this.resourceList.filter((resource) => {
        return resource.name.toLowerCase().includes(searchText);
      });
      this.noResourcesFound = this.filteredResources.length === 0;
    } else {
      this.filteredResources = [];
      this.noResourcesFound = false;
    }

    this.isButtonDisabled = !this.filteredResources.some((resource) =>
      resource.name.toLowerCase().includes(searchText)
    );
  }

  onResourceSelected(event: any): void {
    this.selectedResourceId = event.option.id;
    this.noResourcesFound = false;
  }

  onCostCenterSelected(costCenterId: string) {
    this.loadProjectList(costCenterId);
  }

  loadProjectList(costCenterId: string): void {
    this.errorMessage = '';
    this.projectService
      .getProjectByCostCentre(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        costCenterId
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response: any) => {
          const projects = response.data.records;
          if (projects.length === 0) {
            this.errorMessage =
              'No projects found. Please select a different cost centre.';
          } else {
            this.projectList = projects;
          }
        },
        error: (error: any) => {
          console.error('Error loading projects:', error);
          this.errorMessage =
            'An error occurred while loading projects. Please try again later.';
        },
      });
  }

  onSubmit(): void {
    if (this.resourceForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;

      if (this.isEditMode) {
        const updatedResourceData = this.resourceForm.value;
        delete updatedResourceData.projectId;
        updatedResourceData.resourceID =
          this.resourceForm.get('resourceID')?.value;
        this.resourceService
          .updateResource(updatedResourceData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => this.matDialog.close({ success: true }),
            error: (error: any) => console.log('error', error),
          });
      } else {
        const newResourceData = {
          ...this.resourceForm.value,
        };
        delete newResourceData.resourceID;
        this.resourceService
          .createResource(newResourceData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => this.matDialog.close({ success: true }),
            error: (error: any) => {
              this.errorMessage =
                error?.error?.message || 'Invalid phone number or add +91.';
            },
          });
      }
    } else {
      console.error('Invalid form data.');
    }
  }

  onSubmissionSuccess(): void {
    this.isSubmitting = false;
    this.matDialog.close({ success: true });
  }

  onSubmissionError(error: any): void {
    this.isSubmitting = false;
    console.error('Error submitting form:', error);
  }

  numericInputValidity: { [key: string]: boolean } = {
    phone: true,
    salary: true,
  };

  handleNumericInputValidityChange(field: string, validity: boolean) {
    this.numericInputValidity[field] = validity;
  }

  areAllNumericInputsValid(): boolean {
    return Object.values(this.numericInputValidity).every((valid) => valid);
  }

  close() {
    this.matDialog.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
